/* DataPopup.css */
.table-wrapper {
  max-height: 60vh;
  /* Adjust the height as needed */
  overflow-y: auto;
  /* Enable vertical scroll */
}

/* Customize scrollbar */
.table-wrapper::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

.table-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the track */
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scroll thumb */
  border-radius: 10px;
}

.table-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scroll thumb on hover */
}

.table-wrapper th{
  background-color: #9e9e9e !important;
}

.table-wrapper td{
  background-color: #d0d0d0 !important;
}