html,
body,
#root,
.content{
  height: 100%;
  width: 100%;
  /* font-family: 'Source Sans Pro',sens-serif; */
  overflow-x: hidden;
  scroll-behavior: smooth;

  font-family: "Söhne", ui-sans-serif, system-ui, "-apple-system", "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

/* Scrollbar */
.messages::-webkit-scrollbar {
  width: 7px;
}

.messages::-webkit-scrollbar-track {
  display: none;
}

.messages::-webkit-scrollbar-thumb {
  background: #80818b;
  border-radius: 10px;
  height: 2em !important;
}

.messages::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.fill-available {
  width: 82vw;
}
.ant-tabs-nav{
  margin-bottom: 0!important;
}

.btnhoverprop:hover {
  background-color: #f0f0f0 !important;
  .fade-button {
    display: block !important;
  }
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}


.fade-button {
  padding: .7rem;
   background-color: #528552;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
